export const ACCOUNT_APPLICATION_STATUSES = {
  DRAFT: {
    text: 'Черновик',
    value: 'DRAFT',
  },
  ON_REVIEWER_APPROVING: {
    text: 'На согласовании',
    value: 'ON_REVIEWER_APPROVING',
  },
  APPROVED: {
    text: 'Согласовано',
    value: 'APPROVED',
  },
  REJECTED: {
    text: 'Отказано',
    value: 'REJECTED',
  },
  ON_REWORK: {
    text: 'На доработке',
    value: 'ON_REWORK',
  },
  SAVED: {
    text: 'Сохранено',
    value: 'SAVED',
  },
  ON_DATA_PREPARING: {
    text: 'Получение донных из САП',
    value: 'ON_DATA_PREPARING',
  }
}

export const ACCOUNT_APPLICATION_STATUSES_ADMINISTRATOR =  {
  DRAFT: ACCOUNT_APPLICATION_STATUSES['DRAFT'],
  ON_REVIEWER_APPROVING: ACCOUNT_APPLICATION_STATUSES['ON_REVIEWER_APPROVING'],
  APPROVED: ACCOUNT_APPLICATION_STATUSES['APPROVED'],
  REJECTED: ACCOUNT_APPLICATION_STATUSES['REJECTED'],
  ON_REWORK: ACCOUNT_APPLICATION_STATUSES['ON_REWORK'],
  SAVED: ACCOUNT_APPLICATION_STATUSES['SAVED'],
  ON_DATA_PREPARING: ACCOUNT_APPLICATION_STATUSES['ON_DATA_PREPARING'],
};

export const ACCOUNT_APPLICATION_STATUSES_INITIATOR =  {
  DRAFT: ACCOUNT_APPLICATION_STATUSES['DRAFT'],
  ON_REVIEWER_APPROVING: ACCOUNT_APPLICATION_STATUSES['ON_REVIEWER_APPROVING'],
  APPROVED: ACCOUNT_APPLICATION_STATUSES['APPROVED'],
  ON_REWORK: ACCOUNT_APPLICATION_STATUSES['ON_REWORK'],
  SAVED: ACCOUNT_APPLICATION_STATUSES['SAVED'],
  ON_DATA_PREPARING: ACCOUNT_APPLICATION_STATUSES['ON_DATA_PREPARING'],
};

export const ACCOUNT_APPLICATION_TYPES = {
  INITIATOR: {
    text: 'Заявитель',
    value: 'account_user',
  },
  ADMINISTRATOR: {
    text: 'Администратор',
    value: 'account_admin_manager',
  },
  ACCOUNT_ADMINISTRATOR: {
    text: 'Администратор аккаунтов',
    value: 'admin_account_authority',
  },
  ACCOUNT_AUTHORITY: {
    text: 'Администратор полномочий',
    value: 'admin_account_authority',
  },
  ACCOUNT_SLAVE: {
    text: 'Сотрудник организации',
    value: 'account_slave',
  },
  ACCOUNT_OBSERVER: {
    text: 'Наблюдатель',
    value: 'account_observer',
  }
};

export const ACCOUNT_APPLICATION_CREATE_TYPES = {
  CREATE: {
    text: 'Заявка на создание',
    value: 'CREATE',
  },
  DELETE: {
    text: 'Заявка на удаление',
    value: 'DELETE',
  },
  EDIT: {
    text: 'Заявка на изменение',
    value: 'EDIT',
  },
  MANUAL: {
    text: 'Ручное создание',
    value: 'MANUAL',
  },
};

export const ACCOUNT_APPLICATION_ACTIONS = {
  SEND_TO_APPROVE_BY_INITIATOR: {
    text: 'Отправить на согласование',
    value: 'SEND_TO_APPROVE_BY_INITIATOR',
  },
  REVOKE_BY_INITIATOR: {
    text: 'Отозвать с согласования',
    value: 'REVOKE_BY_INITIATOR',
  },
  APPROVE_BY_REVIEWER: {
    text: 'Согласовать',
    value: 'ACCOUNT_ADMIN.APPROVE_BY_REVIEWER',
  },
  REJECT_BY_REVIEWER: {
    text: 'Отклонить',
    value: 'ACCOUNT_ADMIN.REJECT_BY_REVIEWER',
  },
  SEND_TO_REWORK_BY_REVIEWER: {
    text: 'Отправить на доработку',
    value: 'ACCOUNT_ADMIN.SEND_TO_REWORK_BY_REVIEWER',
  },
  SEND_FROM_REWORK_BY_INITIATOR: {
    text: 'Отправить на согласование после доработки',
    value: 'ACCOUNT_ADMIN.SEND_FROM_REWORK_BY_INITIATOR',
  },
  SAVE: {
    text: 'Создать аккаунт организации',
    value: 'SAVE',
  },
}

export default {
  ACCOUNT_APPLICATION_TYPES,
  ACCOUNT_APPLICATION_STATUSES_ADMINISTRATOR,
  ACCOUNT_APPLICATION_STATUSES_INITIATOR,
  ACCOUNT_APPLICATION_ACTIONS
}
