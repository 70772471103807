<template>
  <AccountUsers :page="page" :size="size" :filter="filter"/>
</template>

<script>
import AccountUsers from '@/components/account-users/AccountUsers/AccountUsers.vue';
export default {
  name: 'AccountUsersView',
  components: {
    AccountUsers,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
}
</script>
