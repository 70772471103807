<template>
  <div :class="$style.root">
    <VDataTable
      fixed-header
      height="calc(100vh - 305px)"
      hideDefaultHeader
      :headers="headers"
      :items="items"
      :loading="loading"
      :footer-props="footer"
      :options.sync="options"
      :server-items-length="count"
      :mobile-breakpoint="0"
      @click:row="({ id }) => onDetail(id)"
      @update:page="page => onPagination({ page })"
      @update:items-per-page="size => onPagination({ size })"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
        <tr>
          <th v-for="{text, colspan } in headers" class="text-start" :colspan="colspan" :key="text">{{text}}</th>
        </tr>
        <FilterRow :items="headers" @input="onPagination" />
        </thead>
      </template>
      <template v-slot:item.status="{ item: { status } }">
        <CompactCeil :text="get(statuses[status], 'text', status)" />
      </template>
    </VDataTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {get, isEqual, pickBy} from 'lodash-es';
import FilterRow from '@/components/inspect/FilterRow/FilterRow';
import CompactCeil from '@/components/inspect/ApplicationList/components/CompactCeil';
import {
  ACCOUNT_USERS_STATUSES
} from '@/store/account-users/enums';

export default {
  name: 'AccountUsersList',
  components: {
    CompactCeil,
    FilterRow,
  },
  props: {
    headers: { type: Array },
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
  },
  // TODO: убрать пустые значения
  data: function() {
    return {
      footer: {
        itemsPerPageText: '',
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
      },
      options: {
        page: this.page,
        itemsPerPage: this.size,
      },
    }
  },
  computed: {
    ...mapGetters({}),
    statuses() {
      return ACCOUNT_USERS_STATUSES;
    },
  },
  methods: {
    get,
    onDetail(id) {
      this.$router.push({ name: 'account-users/AccountUsersApplicationView', params: { application: id }});
    },
    onPagination(params) {
      const { query } = this.$route;
      const path = pickBy({ ...query, ...params }, Boolean);
      if (!isEqual(query, path)) this.$router.push({ query: path });
    },
  }
}
</script>

<style module lang="scss">
.root {
  td {
    width: 500px;
    min-width: 130px;
    &:nth-child(1) { max-width: 50px; min-width: 50px; }
    &:nth-child(2) { max-width: 100px; }
    &:nth-child(3) { max-width: 105px; }
    &:nth-child(4) { max-width: 130px; }
    &:nth-child(5) { max-width: 100px; }
  }
}
</style>
